import React, { useState, useEffect } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
// import * as staticTextKey from '../../utils/translation-variable';
import userLogo from '../static/imgs/userLogo.png';
import phoneIcon from '../static/imgs/phone.png';
import Http from '../services/http';
import { dataSources } from '../utils/data-sources';
import { TextField, Button, Backdrop, CircularProgress } from '@material-ui/core';
import { FooterMenu } from '../components/footer-menu';
import { AppLayout } from '../components/app-layout';
import theme from '../utils/theme';
import { Cookies } from 'react-cookie';
import { useHistory } from 'react-router-dom';
import PhoneInput, { isValidPhoneNumber, isPossiblePhoneNumber } from 'react-phone-number-input';
import { makeStyles } from '@material-ui/core/styles';
// import Input , {isPossiblePhoneNumber, isValidPhoneNumber} from 'react-phone-number-input/input'

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  }
}));

export function SMS(props) {
  const classes = useStyles();
  const cookies = new Cookies();
  const http = Http.getInstance();
  const dataSource = dataSources();
  const history = useHistory();
  //const mfaPhoneNumb = cookies.get('mobile_number') === "null" ? '' : cookies.get('mobile_number') 
  const [loading, setLoading] = useState(false);
  const [phoneNumb, setPhoneNumb] = useState(cookies.get('mobile_number'));
  const [error, setError] = useState(false);
  const [emptyError, setEmptyError] = useState(false);
  const [translations, setTranslations] = useState({
      SmsTitle: 'Multifactor Authentication setup for SMS verification',
      SmsText: 'Adding phone number authentication ensure that only authorized individuals can access information',
      AddPhoneTitle: 'Add your Phone Number',
      AddPhoneText: 'Enter your Phone number to which authentication code has to be sent',
      ErrorText: 'Enter a valid phone number',
      smsEmptyError: 'Please enter phone number',
      AddPhonebtn: 'Add Phone Number',
      Backbtn: 'Back',
      phoneNumbDisclaimer: '(Entered Phone number will be used only for security purpose)'
  });

 // const translationData = JSON.parse(localStorage.getItem('translations') || '{}');

  let SmsTitle;
  let SmsText;
  let AddPhoneTitle;
  let AddPhoneText;
  let ErrorText;
  let smsEmptyError;
  let AddPhonebtn;
  let Backbtn;
  let phoneNumbDisclaimer;

  useEffect(()=>{
    const translationData = JSON.parse(localStorage.getItem('translations') || '{}');
    if (translationData) {
      setTranslations({SmsTitle: translationData['portal-sms_title'] || 'Multifactor Authentication setup for SMS verification',
      SmsText:  translationData['portal-sms_text'] || 'Adding phone number authentication ensure that only authorized individuals can access information',
      AddPhoneTitle: translationData['portal-sms_add_phone_title'] || 'Add your Phone Number',
      AddPhoneText: translationData['portal-email_add_phone_text'] || 'Enter your Phone number to which authentication code has to be sent',
      ErrorText: translationData['portal-sms_add_phone_error_text'] || 'Enter a valid phone number',
      smsEmptyError: translationData['portal-sms_add_empty_phone_error_text'] || 'Please enter phone number', 
      AddPhonebtn: translationData['portal-sms_add_phone_button'] || 'Add Phone Number',
      Backbtn: translationData['portal-sms_add_phone_back_button'] || 'Back',
      phoneNumbDisclaimer: translationData['portal-sms_security_disclaimer_text'] || '(Entered Phone number will be used only for security purpose)'
    })
    }
  },[])

  const handlePhoneNumb = phoneNumb => {
    setError(false);
    setEmptyError(false);
    if (phoneNumb) {
      if (phoneNumb && phoneNumb.length > 2) {
        setPhoneNumb(phoneNumb);
      }
      // if (phoneNumb.length === 2 && error == true) {
      //   setError(false);
      // }
    }
    else{
      setPhoneNumb(undefined); 
    }
  };

  const addPhoneNumb = () => {
    if(phoneNumb == "null" || phoneNumb === undefined){
      setEmptyError(true);
    }
    else if(phoneNumb.length === 0){
      setEmptyError(true);
    }
    else{
    setLoading(true);
    if (phoneNumb && phoneNumb!=="null") {
      if (isPossiblePhoneNumber(phoneNumb) && isValidPhoneNumber(phoneNumb)) {
        setError(false);
        const user = {
          mobile_number: phoneNumb,
        };
        http.patchData(`${dataSource.users}`, user, '')
        .then(res => {
            if (res.status === 200) {
              console.log('user updated', res);
              cookies.set('mobile_number', phoneNumb, {
                path: '/',
                domain: `${process.env.REACT_APP_API_SERVER_sub_domian}`,
              });
              //window.location.href="/verifyPhone";
              history.push({
                pathname: '/myaccount/mfa/verifyPhone'
                // default_mfa_type:
                //   (props.location.default_mfa_type !== undefined || props.location.default_mfa_type !== null) &&
                //   props.location.default_mfa_type,
              });
              setLoading(false);
            }
          })
          .catch(error => {
            console.log(error);
            setLoading(false);
          });
      } else {
        console.log('error');
        setError(true);
        setLoading(false);
      }
    }
  }
    // console.log(isPossiblePhoneNumber(phoneNumb) , isValidPhoneNumber(phoneNumb),isPossiblePhoneNumber(phoneNumb) && isValidPhoneNumber(phoneNumb),"isValid");
    // if(phoneNumb){
    //   console.log(error);
    //   if (error === false) {

    //   }
    // }
  };

  const authorised_user = cookies.get('authorised_user');
  if (authorised_user === 'true') {
    return (
      <React.Fragment>
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <ThemeProvider theme={theme}>
          <div dir="ltr">
            <AppLayout
              apps={JSON.parse(localStorage.getItem('apps'))}
              errorDisplay={localStorage.getItem('errorDisplay') === 'false' ? false : true}
              errorResponse={localStorage.getItem('error')}
            />

            <div className="totpRoot">
              <h5 className="totpTitle">
                {translations.SmsTitle}
              </h5>

              <div className="totp">
                <div className="emailSection">
                  <img alt="user" className="userLogo" height="95px" src={userLogo} />
                  <div>
                    {translations.SmsText}
                  </div>
                </div>

                <div className="otpVerifySection">
                  <img alt="email" className="emailUserLogo" src={phoneIcon} />
                  <h5>{translations.AddPhoneTitle}</h5>
                  <div>
                    <div>{translations.AddPhoneText}
                    </div>
                    <div>{translations.phoneNumbDisclaimer}
                    </div>
                  </div>

                  {/* <TextField
                              className="otpField"
                              variant="outlined"
                              fullWidth
                              value={phoneNumb}
                              required
                              onChange={(e) => handlePhoneNumb(e)}
                              onKeyDown={e => {
                                if (e.keyCode === 13) {
                                    e.preventDefault();
                                    addPhoneNumb();
                                }
                              }}
                            /> */}

                  <PhoneInput
                    className="phoneNumbField"
                    international={false}
                    addInternationalOption={false}
                    value={phoneNumb}
                    defaultCountry="US"
                    countries={['IN', 'US']}
                    onChange={e => handlePhoneNumb(e)}
                    onKeyDown={e => {
                      if (e.keyCode === 13) {
                        e.preventDefault();
                        addPhoneNumb();
                      }
                    }}
                  />

                  {/* 
                            <Input
                            className="phoneNumbField"
                            fullWidth
                            country="US"
                            international
                            withCountryCallingCode
                            value={phoneNumb}
                            onChange={(e) => handlePhoneNumb(e)}
                            onKeyDown={e => {
                              if (e.keyCode === 13) {
                                  e.preventDefault();
                                  addPhoneNumb();
                              }
                            }}
                            /> */}

                  {error && (
                    <div className="otpErrorMsg">
                      {translations.ErrorText}
                    </div>
                  )}


                  {emptyError && (
                    <div className="otpErrorMsg">
                      {translations.smsEmptyError}
                    </div>
                  )}

                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      addPhoneNumb();
                    }}
                    className="bni-background-color classes.button MuiButton-fullWidth button-focus-disable activateBtn">
                    {translations.AddPhonebtn}
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      window.location.href = '/myaccount/mfa/settings';
                    }}
                    id="cancel"
                    className="classes.button MuiButton-fullWidth button-focus-disable navigateMethod">
                    {translations.Backbtn}
                  </Button>
                </div>
              </div>
            </div>

            <FooterMenu />
          </div>
        </ThemeProvider>
      </React.Fragment>
    );
  } else {
    window.location.href = '/';
  }
}
