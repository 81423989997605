import React, { useState, useEffect, useRef } from 'react';
import Http from '../services/http';
import { dataSources } from '../utils/data-sources';
import { Redirect, useHistory } from 'react-router-dom';
import { AppLayout } from '../components/app-layout';
import { ThemeProvider } from '@material-ui/core/styles';
import {
  Button,
  Select,
  MenuItem,
  Modal,
  IconButton,
  Typography,
  Divider,
  Backdrop, 
  CircularProgress
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import DoneIcon from '@material-ui/icons/Done';
import { Cookies } from 'react-cookie';
import { FooterMenu } from '../components/footer-menu';
import * as staticTextKey from '../utils/translation-variable';
import theme from '../utils/theme';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  modal: {
    position: 'absolute',
    background: 'white',
    width: 440,
    height: 150,
    boxShadow: theme.shadows[5],
  },
  disableModal: {
    position: 'absolute',
    background: 'white',
    width: 570,
    height: 170,
    boxShadow: theme.shadows[5],
  },
  warningModal: {
    position: 'absolute',
    background: 'white',
    width: 490,
    height: 165,
    boxShadow: theme.shadows[5],
  }
}));

function getModalStyle() {
  return {
    top: '50%',
    left: '50%',
    transform: `translate(-50%, -50%)`
  };
}

export function SettingsPage(props) {
  //   const totp = useRef(null);
  //   const email = useRef(null);
  //   const sms = useRef(null);
  const cookies = new Cookies();
  const http = Http.getInstance();
  const dataSource = dataSources();
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const authorised_user = cookies.get('authorised_user');
  const mfa_enabled = cookies.get('mfa_enabled');
  const mfa_method = cookies.get('default_mfa_type');
  const [enabled, setEnabled] = useState(cookies.get('mfa_enabled') === 'false' ? false : true);
  const [mfaTypes, setMfaTypes] = useState([]);
  const [selectedType, setSelectedType] = useState(mfa_method);
  const [userModified, setUserModified] = useState(false);
  const [disableConfirmAlert, setDisableConfirmAlert] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [showDeleteSuccess, setShowDeleteSuccess] = useState(false);
  const totpVerified = cookies.get('totp_verified') === 'false' ? false : true;
  const emailVerified = cookies.get('email_verified') === 'false' ? false : true;
  const smsVerified = cookies.get('mobile_number_verified') === 'false' ? false : true;
  const [translations, setTranslations] = useState({
      MyAccount: "My Account",
      multiFactorAuthenticationTitle : 'Multi-Factor Authentication',
      multiFactorAuthenticationText : 'Multi Factor Authentication(MFA) enhances security by requiring an additional verification step during login, which helps prevent unauthorized access',
      Enable : "Enabled",
      Disable : "Disabled",
      SelectMethod : 'Select a preferred method',
      UpdateMessage : 'MFA method is updated successfully',
      Success : 'SUCCESS',
      Okbutton : 'OK',
      Warning : 'WARNING',
      MFAMethodDeleteMsg : "MFA method deleted successfully!!",
      DeleteWarningMsg : "Please select any other MFA type as a default method before you delete!",
      AuthenticationType : 'Types of Authentication',
      TotpTitle : 'Authenticator app verification',
      TotpText : 'For users, they will need to scan a QR code and complete the process to setup.',
      EmailTitle : 'Email verification',
      EmailText : 'For users, they will need to verify their email and setup.',
      SmsTitle : 'SMS verification',
      SmsText : 'For users, they will need to verify through SMS and setup.',
      Cancelbutton : 'CANCEL',
      TotpValue : 'Authenticator App',
      EmailValue : 'EMAIL',
      SmsValue : 'SMS',
      confirmationText : "CONFIRMATION",
      disableConfirmMsg : "Do you really want to disable MFA? If you disable your MFA methods will be reset!"
});

  let MyAccount;
  let multiFactorAuthenticationTitle;
  let multiFactorAuthenticationText;
  let Enable;
  let Disable;
  let SelectMethod;
  let UpdateMessage;
  let Success;
  let Okbutton;
  let Warning;
  let DeleteWarningMsg;
  let MFAMethodDeleteMsg;
  let AuthenticationType;
  let TotpTitle;
  let TotpText;
  let EmailTitle;
  let EmailText;
  let SmsTitle;
  let SmsText;
  let Cancelbutton;
  let TotpValue;
  let EmailValue;
  let SmsValue;
  let confirmationText;
  let disableConfirmMsg;

  useEffect(()=>{
    const translationData = JSON.parse(localStorage.getItem('translations') || '{}');
    if (translationData) {
      setTranslations({MyAccount: translationData['portal-launcher-my_account'] || "My Account",
      multiFactorAuthenticationTitle :
        translationData['portal-settings_multi_factor_authentication_title'] || 'Multi-Factor Authentication',
      multiFactorAuthenticationText :
        translationData['portal-settings_multi_factor_authentication_text'] || 'Multi Factor Authentication(MFA) enhances security by requiring an additional verification step during login, which helps prevent unauthorized access',
      Enable : translationData['portal-settings_multi_factor_authentication_enabled_button'] || "Enabled",
      Disable : translationData['portal-settings_multi_factor_authentication_disabled_button'] || "Disabled",
      SelectMethod : translationData['portal-settings_select_ preferred_method'] || 'Select a preferred method',
      UpdateMessage :
        translationData['portal-settings_select_ preferred_method_update_success_message_text'] || 'MFA method is updated successfully',
      Success :
        translationData['portal-settings_select_ preferred_method_update_success_message_title'] || 'SUCCESS',
      Okbutton :
        translationData['portal-settings_select_ preferred_method_update_success_message_ok'] || 'OK',
      Warning : translationData['portal-settings_mfa_type_delete_warning'] || 'WARNING',
      MFAMethodDeleteMsg : translationData['portal-settings_mfa_type_delete_success_msg'] || "MFA method deleted successfully!!",
      DeleteWarningMsg : translationData['portal-settings_mfa_type_delete_warning_msg'] || "Please select any other MFA type as a default method before you delete!",
      AuthenticationType : translationData['portal-settings_toa_title'] || 'Types of Authentication',
      TotpTitle : translationData['portal-settings_toa_totp_title'] || 'Authenticator app verification',
      TotpText : translationData['portal-settings_toa_totp_text'] || 'For users, they will need to scan a QR code and complete the process to setup.',
      EmailTitle : translationData['portal-settings_toa_email_title'] || 'Email verification',
      EmailText : translationData['portal-settings_toa_email_text'] || 'For users, they will need to verify their email and setup.',
      SmsTitle : translationData['portal-settings_toa_sms_title'] || 'SMS verification',
      SmsText : translationData['portal-settings_toa_sms_text'] || 'For users, they will need to verify through SMS and setup.',
      Cancelbutton : translationData['portal-settings_cancel_button'] || 'CANCEL',
      TotpValue : translationData['portal-settings_select_ preferred_method_totp'] || 'Authenticator App',
      EmailValue : translationData['portal-settings_select_ preferred_method_email'] || 'EMAIL',
      SmsValue : translationData['portal-settings_select_ preferred_method_sms'] || 'SMS',
      confirmationText : translationData['portal-settings_confirmation_text'] ||  "CONFIRMATION",
      disableConfirmMsg : translationData['portal-settings_mfa_type_disable_confirmation_msg'] || "Do you really want to disable MFA? If you disable your MFA methods will be reset!"
    })
    }
  },[])

  useEffect(() => {
    localStorage.removeItem('VerifyTranslations');
    const default_mfa_type = cookies.get('default_mfa_type').type;
    setLoading(true);
    if (enabled && (mfaTypes.length == 0)) {
      http.getData(`${dataSource.mfaMethods}`, '', '')
        .then(response => {
          setMfaTypes(response.data.mfa_details);
          setLoading(false);
          // if(mfa_type.toLowerCase() === "totp"){
          //     totp.current.checked = "checked";
          // }
          // else if(mfa_type.toLowerCase() === "email"){
          //     email.current.checked = "checked";
          // }
          // else if(mfa_type.toLowerCase() === "sms"){
          //     sms.current.checked = "checked";
          // }
        })
        .catch(error => {
          setLoading(false);
          console.log('error', error);
        });
    }
    else {
      setLoading(false);
    }
  }, [enabled]);

  const mfaSelection = data => {
    if (data === false && mfa_method !== "null") {
      setDisableConfirmAlert(true);
    }
    else if (data === false && mfa_method === "null") {
      setEnabled(data);
      setLoading(false);
    }
    else {
      setEnabled(data);
      setLoading(true);
    }
  };

  const disableMfa = () => {
    setLoading(true);
      const user = {
        mfa_enabled: false
      };
      http.patchData(`${dataSource.users}`, user, '')
        .then(res => {
          if (res.status === 200) {
            http.getData(`${dataSource.mfaMethods}`, '', '')
            .then(response => {
              cookies.set('mfa_enabled', response.data.mfa_enabled, {
                path: '/',
                domain: `${process.env.REACT_APP_API_SERVER_sub_domian}`,
              });
              cookies.set('default_mfa_type', response.data.default_mfa_type, {
                  path: '/',
                  domain: `${process.env.REACT_APP_API_SERVER_sub_domian}`,
              });
              cookies.set('totp_verified', response.data.mfa_details[0].totp_verified, {
                  path: '/',
                  domain: `${process.env.REACT_APP_API_SERVER_sub_domian}`,
              });
              cookies.set('email_verified', response.data.mfa_details[1].email_verified, {
                  path: '/',
                  domain: `${process.env.REACT_APP_API_SERVER_sub_domian}`,
              });
              cookies.set('mobile_number_verified', response.data.mfa_details[2].mobile_number_verified, {
                  path: '/',
                  domain: `${process.env.REACT_APP_API_SERVER_sub_domian}`,
              });
              cookies.set('mfa_email', response.data.mfa_details[1].email, {
                  path: '/',
                  domain: `${process.env.REACT_APP_API_SERVER_sub_domian}`,
              });
              cookies.set('mobile_number', response.data.mfa_details[2].mobile_number, {
                  path: '/',
                  domain: `${process.env.REACT_APP_API_SERVER_sub_domian}`,
              });
              setEnabled(false);
              setLoading(false);
            })
            .catch(error => {
              console.log('error', error);
              setLoading(false);
            });
          }
        })
        .catch(error => {
          console.log(error);
          setLoading(false);
        });
    // } else {
    //   setEnabled(data);
    //   if (
    //     mfa_method !== undefined &&
    //     mfa_method !== null &&
    //     mfa_method !== 'null' &&
    //     mfa_method !== 'undefined'
    //   ) {
    //     const user = {
    //       mfa_enabled: true,
    //     };
    //     http
    //       .patchData(`${dataSource.users}`, user, '')
    //       .then(res => {
    //         if (res.status === 200) {
    //           cookies.set('mfa_enabled', true, {
    //             path: '/',
    //             domain: `${process.env.REACT_APP_API_SERVER_sub_domian}`,
    //           });
    //         }
    //       })
    //       .catch(error => {
    //         console.log(error);
    //       });
    //   }
  }

  const activateMFA = type => {
    setSelectedType(type);
    setUserModified(true);
    // cookies.set('mfa_enabled', enabled, {
    //         path: '/',
    //         domain: `${process.env.REACT_APP_API_SERVER_sub_domian}`,
    // });
    // cookies.set('mfa_type', type, {
    //         path: '/',
    //         domain: `${process.env.REACT_APP_API_SERVER_sub_domian}`,
    // });
    
    // const user = {
    //                 mfa_enabled: enabled,
    //                 mfa_type: {"mfa_id": selectedType.mfa_id , "type": selectedType.type}
    //              };
    // http.patchData(`https://iam.bniportal.dev/users`, user , '')
    // .then(res => {
    //     if (res.status === 200) {
    //       console.log("user mfa updated",res);
    //       cookies.set('mfa_enabled', enabled, {
    //         path: '/',
    //         domain: `${process.env.REACT_APP_API_SERVER_sub_domian}`,
    //       });
    //       setUserModified(true);
    //     }
    //   })
    //   .catch(error => {
    //     console.log(error);
    //   });
  };

  const handlePreferedMethod = event => {
    const preferedMethod = JSON.parse(event.target.value);
    setSelectedType(preferedMethod);
    const user = {
      default_mfa_type: preferedMethod,
      mfa_enabled: true,
    };
    http.patchData(`${dataSource.users}`, user, '')
      .then(res => {
        if (res.status === 200) {
          cookies.set('mfa_enabled', true, {
            path: '/',
            domain: `${process.env.REACT_APP_API_SERVER_sub_domian}`,
          });
          cookies.set('default_mfa_type', preferedMethod, {
            path: '/',
            domain: `${process.env.REACT_APP_API_SERVER_sub_domian}`,
          });
          setOpenAlert(true);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  const onCancelAction = () => {
    window.location.href = '/launcher';
  };

  const deleteMfaMethod = (mfa_id, mfaType) => {
    setLoading(true);
    http.getData(`${dataSource.mfaMethods}`, '', '')
        .then(response => {
          const total_mfa_types = response.data.total_mfa_types;
          const deleted_mfa_types = response.data.deleted_mfa_types;
          if(total_mfa_types - deleted_mfa_types === 1 || total_mfa_types - deleted_mfa_types === 0 ){
                setDeleteAlert(false);
                http.deleteData(`${dataSource.mfaMethods}/${mfa_id}`, '', '')
                .then(res => {
                  const user = {
                    mfa_enabled: false,
                  };
                  http.patchData(`${dataSource.users}`, user, '')
                    .then(res => {
                      if (res.status === 200) {
                        cookies.set('mfa_enabled', false, {
                          path: '/',
                          domain: `${process.env.REACT_APP_API_SERVER_sub_domian}`,
                        });
                        cookies.set('default_mfa_type', null, {
                          path: '/',
                          domain: `${process.env.REACT_APP_API_SERVER_sub_domian}`,
                        });
                        cookies.set('totp_verified', false, {
                          path: '/',
                          domain: `${process.env.REACT_APP_API_SERVER_sub_domian}`,
                      });
                      cookies.set('email_verified', false, {
                          path: '/',
                          domain: `${process.env.REACT_APP_API_SERVER_sub_domian}`,
                      });
                      cookies.set('mobile_number_verified', false, {
                          path: '/',
                          domain: `${process.env.REACT_APP_API_SERVER_sub_domian}`,
                      });
                      cookies.set('mfa_email', null, {
                          path: '/',
                          domain: `${process.env.REACT_APP_API_SERVER_sub_domian}`,
                      });
                      cookies.set('mobile_number', null, {
                          path: '/',
                          domain: `${process.env.REACT_APP_API_SERVER_sub_domian}`,
                      });
                      setEnabled(false);
                      setLoading(false);
                      setShowDeleteSuccess(true);
                      }
                    })
                    .catch(error => {
                      console.log("user update error -> ",error);
                      setLoading(false);
                    });
                })
                .catch(error => {
                  console.log("mfa delete error -> ", error);
                  setLoading(false);
                });
          }
          else{
            if(mfa_method.mfa_id === mfa_id){
                    setShowDeleteSuccess(false);
                    setLoading(false);
                    setDeleteAlert(true);
            }
            else{
              setDeleteAlert(false);
              http.deleteData(`${dataSource.mfaMethods}/${mfa_id}`, '', '')
                .then(res => {
                  const user = {
                            [mfaType]: false,
                  };
                  http.patchData(`${dataSource.users}`, user, '')
                  .then(res => {
                            if (res.status === 200) {
                                cookies.set(`${mfaType}`, false, {
                                path: '/',
                                domain: `${process.env.REACT_APP_API_SERVER_sub_domian}`,
                                });
                                // if(mfaType === "totp_verified"){
                                //     setTotpVerified(false);
                                // }
                                // else if(mfaType === "email_verified"){
                                //     setEmailVerified(false);
                                // }
                                // else if(mfaType === "mobile_number_verified"){
                                //     setSmsVerified(false);
                                // }
                                setLoading(false);
                                setShowDeleteSuccess(true);
                            }
                    })
                    .catch(error => {
                      console.log("user update error -> ",error);
                      setLoading(false);
                    });
                })
                .catch(error => {
                  console.log("delete mfa method error -> ", error);
                  setLoading(false);
                });
            }
          }
        })
        .catch(error => {
          console.log('fetch mfa methods error ->', error);
          setLoading(false);
        });
        
    // if(mfa_method.mfa_id === mfa_id){
    //     setShowDeleteSuccess(false);
    //     setDeleteAlert(true);
    // }
    // else {
    //     setDeleteAlert(false);
    //     http.deleteData(`${dataSource.mfaMethods}/${mfa_id}`, '', '')
    //     .then(res => {
    //     console.log(res,res);
    //     // const user = {
    //     //     [mfaType]: false,
    //     // };
    //     // http.patchData(`${dataSource.users}`, user, '')
    //     //     .then(res => {
    //     //     if (res.status === 200) {
    //     //         cookies.set(`${mfaType}`, false, {
    //     //         path: '/',
    //     //         domain: `${process.env.REACT_APP_API_SERVER_sub_domian}`,
    //     //         });
    //     //         if(mfaType === "totp_verified"){
    //     //             setTotpVerified(false);
    //     //         }
    //     //         else if(mfaType === "email_verified"){
    //     //             setEmailVerified(false);
    //     //         }
    //     //         else if(mfaType === "mobile_number_verified"){
    //     //             setSmsVerified(false);
    //     //         }
    //     //     }
    //     //     })
    //     //     .catch(error => {
    //     //     console.log(error);
    //     //     });
    //     }).catch(error => {
    //     console.log("error", error);
    //     });
    //     setShowDeleteSuccess(true);
       
    // }
  }

  if (authorised_user === 'true') {
    if (userModified) {
      localStorage.setItem("selectedMFAMethod",JSON.stringify(selectedType));
      history.push({
        pathname: selectedType.type.toLowerCase(),
        //default_mfa_type: selectedType,
      });
      return (
        // <Redirect
        //     to={{
        //         pathname: selectedType.type.toLowerCase(),
        //         mfa_type: selectedType
        //     }}
        // />
        ''
      );
    } else {
      return (
        <React.Fragment>
           <Backdrop className={classes.backdrop} open={loading}>
              <CircularProgress color="inherit" />
            </Backdrop>
          <ThemeProvider theme={theme}>
            <div dir="ltr">
              <div>
                <AppLayout
                  apps={JSON.parse(localStorage.getItem('apps'))}
                  errorDisplay={localStorage.getItem('errorDisplay') === 'false' ? false : true}
                  errorResponse={localStorage.getItem('error')}
                />


                <Modal
                  open={disableConfirmAlert}
                  onClose={() => {
                    setEnabled(true);
                    setDisableConfirmAlert(false);
                  }}
                  aria-labelledby="simple-modal-title"
                  aria-describedby="simple-modal-description">
                  <div style={modalStyle} className={classes.disableModal}>
                    <div className={'dialogHeader'}>
                      <Typography color="textPrimary" className={'disableDialogTitle'}>
                      {translations.confirmationText}
                      </Typography>
                      <IconButton
                        onClick={() => {
                          setEnabled(true)
                          setDisableConfirmAlert(false);
                        }}
                        className={'closeButton'}>
                        <CloseIcon />
                      </IconButton>
                    </div>
                    <Divider />
                    <div className={'successMsg'}>
                    {translations.disableConfirmMsg}
                    </div>
                    <div className="DisableActions">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        setDisableConfirmAlert(false);
                        disableMfa();
                      }}
                      className="bni-background-color classes.button MuiButton-fullWidth button-focus-disable disableActionBtns">
                      {translations.Okbutton}
                    </Button>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        setEnabled(true);
                        setDisableConfirmAlert(false);
                      }}
                      className="classes.button MuiButton-fullWidth button-focus-disable disableActionBtns">
                      {translations.Cancelbutton}
                    </Button>
                    </div>
                  </div>
                </Modal>

                
                <Modal
                  open={openAlert}
                  onClose={() => {
                    setOpenAlert(false);
                  }}
                  aria-labelledby="simple-modal-title"
                  aria-describedby="simple-modal-description">
                  <div style={modalStyle} className={classes.modal}>
                    <div className={'dialogHeader'}>
                      <Typography color="textPrimary" className={'dialogTitle'}>
                        {translations.Success}
                      </Typography>
                      <IconButton
                        onClick={() => {
                          setOpenAlert(false);
                        }}
                        className={'closeButton'}>
                        <CloseIcon />
                      </IconButton>
                    </div>
                    <Divider />
                    <div className={'successMsg'}>
                      {translations.UpdateMessage}
                    </div>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        setOpenAlert(false);
                      }}
                      className="bni-background-color classes.button MuiButton-fullWidth button-focus-disable actionBtn">
                      {translations.Okbutton}
                    </Button>
                  </div>
                </Modal>

                <Modal
                  open={deleteAlert}
                  onClose={() => {
                    setDeleteAlert(false);
                  }}
                  aria-labelledby="simple-modal-title"
                  aria-describedby="simple-modal-description">
                  <div style={modalStyle} className={classes.warningModal}>
                    <div className={'dialogHeader'}>
                      <Typography color="textPrimary" className={'warningTitle'}>
                      {translations.Warning}
                      </Typography>
                      <IconButton
                        onClick={() => {
                            setDeleteAlert(false);
                        }}
                        className={'closeButton'}>
                        <CloseIcon />
                      </IconButton>
                    </div>
                    <Divider />
                    <div className={'successMsg'}>
                    {translations.DeleteWarningMsg}
                    </div>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        setDeleteAlert(false);
                      }}
                      className="bni-background-color classes.button MuiButton-fullWidth button-focus-disable actionBtn">
                      {translations.Okbutton}
                    </Button>
                  </div>
                </Modal>

                <Modal
                  open={showDeleteSuccess}
                  onClose={() => {
                    setShowDeleteSuccess(false);
                  }}
                  aria-labelledby="simple-modal-title"
                  aria-describedby="simple-modal-description">
                  <div style={modalStyle} className={classes.modal}>
                    <div className={'dialogHeader'}>
                      <Typography color="textPrimary" className={'dialogTitle'}>
                      {translations.Success}
                      </Typography>
                      <IconButton
                        onClick={() => {
                            setShowDeleteSuccess(false);
                        }}
                        className={'closeButton'}>
                        <CloseIcon />
                      </IconButton>
                    </div>
                    <Divider />
                    <div className={'successMsg'}>
                    {translations.MFAMethodDeleteMsg}
                    </div>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        setShowDeleteSuccess(false);
                      }}
                      className="bni-background-color classes.button MuiButton-fullWidth button-focus-disable actionBtn">
                      {translations.Okbutton}
                    </Button>
                  </div>
                </Modal>

                <div className="myAccountRoot">
                  <h4>{translations.MyAccount}</h4>
                  <div className="mfaHeader">
                    <div className="mfaTitle">
                      {translations.multiFactorAuthenticationTitle}
                    </div>
                    <div className="mfaContent">
                      <div>
                        {translations.multiFactorAuthenticationText}
                      </div>
                      {/* <div className="mfaEnableBtns">
                                        <button id="mfaEnableBtn" onClick={() => mfaSelection(true)} className={ enabled === true ? 'mfaEnabled' : 'mfaDisabled'}>Enable</button>
                                        <button id="mfaDisableBtn" onClick={() => mfaSelection(false)} className={ enabled === false ? 'mfaEnabled' : 'mfaDisabled'}>Disable</button>
                                    </div> */}

                      <div className="mfaActionBtns">
                        <input
                          type="checkbox"
                          checked={enabled}
                          onChange={e => mfaSelection(!enabled)}
                          className="toggle"
                          id="toggle"
                        />
                        {/* <input type="checkbox" className="toggle" id="toggle" /> */}
                        <label for="toggle">
                          {/* <span onClick={() => mfaSelection(true)} className="on">ON</span>
                                        <span onClick={() => mfaSelection(false)} className="off">OFF</span> */}
                          <span className="on">{translations.Enable}</span>
                          <span className="off">
                            {translations.Disable}
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>

                  {enabled && mfaTypes.length > 0 && (
                    <div className="mfaTypes">
                      {mfa_method !== 'null' &&
                        mfa_method !== undefined &&
                        mfa_method !== null &&
                        mfa_method !== 'undefined' && (
                          <div className="defaultSection">
                            <div className="defaultText">
                              {translations.SelectMethod}
                            </div>
                            <Select
                              className={'defaultDropDown'}
                              variant="outlined"
                              value={JSON.stringify(selectedType)}
                              onChange={handlePreferedMethod}>
                              {mfaTypes[0] && (
                                <MenuItem
                                  key={0}
                                  value={JSON.stringify({
                                    mfa_id: mfaTypes[0].mfa_id,
                                    type: mfaTypes[0].type,
                                  })}
                                  hidden={totpVerified === false}>
                                  {mfaTypes[0].type.toUpperCase() !== undefined && translations.TotpValue}
                                </MenuItem>
                              )}
                              {mfaTypes[1] && (
                                <MenuItem
                                  key={1}
                                  value={JSON.stringify({
                                    mfa_id: mfaTypes[1].mfa_id,
                                    type: mfaTypes[1].type,
                                  })}
                                  hidden={emailVerified === false}>
                                  {mfaTypes[1].type.toUpperCase() !== undefined && translations.EmailValue}
                                </MenuItem>
                              )}
                              {mfaTypes[2] && (
                                <MenuItem
                                  key={2}
                                  value={JSON.stringify({
                                    mfa_id: mfaTypes[2].mfa_id,
                                    type: mfaTypes[2].type,
                                  })}
                                  hidden={smsVerified === false}>
                                  {mfaTypes[2].type.toUpperCase() !== undefined && translations.SmsValue}
                                </MenuItem>
                              )}
                            </Select>
                          </div>
                        )}

                      <h4>
                        {translations.AuthenticationType}
                      </h4>

                      {mfaTypes[0] && mfaTypes[0].type.toLowerCase() === 'totp' && (
                        <div className="mfaType">
                          <div>
                            <label>
                              {/* <input 
                                            type="radio"
                                            id="totp"
                                            name="mfaType"
                                            ref={totp}
                                            onChange={e => {
                                                setSelectedType(mfaTypes[0]);
                                                cookies.set('mfa_type', mfaTypes[0], {
                                                    path: '/',
                                                    domain: `${process.env.REACT_APP_API_SERVER_sub_domian}`,
                                                });
                                            }} 
                                            /> */}
                              <span className="mfaMethod">
                                <span>
                                  {translations.TotpTitle}
                                </span>
                                <span className="mfaWrapper">
                                  {translations.TotpText}
                                    {(totpVerified === true) && <DoneIcon className={'tickIcon'}/>}
                                </span>
                              </span>
                            </label>
                          </div>
                          <div className="actionBtns">
                            {totpVerified !== true ? 
                            // (
                            //   <Button
                            //     variant="contained"
                            //     color="primary"
                            //     className="bni-background-color classes.button MuiButton-fullWidth button-focus-disable"
                            //     onClick={() =>
                            //       activateMFA({
                            //         mfa_id: mfaTypes[0].mfa_id,
                            //         type: mfaTypes[0].type,
                            //       })
                            //     }>
                            //     Add
                            //   </Button>
                            // ) : (
                            //   <Button
                            //     variant="contained"
                            //     color="primary"
                            //     className="bni-background-color classes.button MuiButton-fullWidth button-focus-disable"
                            //     onClick={() =>
                            //       activateMFA({
                            //         mfa_id: mfaTypes[0].mfa_id,
                            //         type: mfaTypes[0].type,
                            //       })
                            //     }>
                            //     Edit
                            //   </Button>
                            // )
                            <IconButton onClick={() => activateMFA({"mfa_id" :  mfaTypes[0].mfa_id , "type" :  mfaTypes[0].type})} className={'addIcon'}>
                                <AddCircleIcon />
                            </IconButton> :
                            <IconButton onClick={() => deleteMfaMethod(mfaTypes[0].mfa_id, 'totp_verified')} className={'deleteIcon'}>
                                <DeleteIcon />
                            </IconButton>
                            }
                          </div>
                        </div>
                      )}
                      {mfaTypes[1] && mfaTypes[1].type.toLowerCase() === 'email' && (
                        <div className="mfaType">
                          <div>
                            <label>
                              {/* <input 
                                        type="radio" 
                                        name="mfaType"
                                        id="email"
                                        ref={email}
                                        onChange={e => {
                                            setSelectedType(mfaTypes[1]);
                                            cookies.set('mfa_type', mfaTypes[1], {
                                                path: '/',
                                                domain: `${process.env.REACT_APP_API_SERVER_sub_domian}`,
                                            });   
                                        }}
                                        /> */}
                              <span className="mfaMethod">
                                <span>
                                  {translations.EmailTitle}
                                </span>
                                <span className="mfaWrapper">
                                  {translations.EmailText}
                                    {emailVerified === true && <DoneIcon className="tickIcon"/>}
                                </span>
                              </span>
                            </label>
                          </div>
                          <div className="actionBtns">
                            {emailVerified !== true ?
                            //  (
                            //   <Button
                            //     variant="contained"
                            //     color="primary"
                            //     className="bni-background-color classes.button MuiButton-fullWidth button-focus-disable"
                            //     onClick={() =>
                            //       activateMFA({
                            //         mfa_id: mfaTypes[1].mfa_id,
                            //         type: mfaTypes[1].type,
                            //       })
                            //     }>
                            //     Add
                            //   </Button>
                            // ) : (
                            //   <Button
                            //     variant="contained"
                            //     color="primary"
                            //     className="bni-background-color classes.button MuiButton-fullWidth button-focus-disable"
                            //     onClick={() =>
                            //       activateMFA({
                            //         mfa_id: mfaTypes[1].mfa_id,
                            //         type: mfaTypes[1].type,
                            //       })
                            //     }>
                            //     Edit
                            //   </Button>
                            // )
                            <IconButton onClick={() => activateMFA({"mfa_id" :  mfaTypes[1].mfa_id , "type" :  mfaTypes[1].type})} className={'addIcon'}>
                                <AddCircleIcon />
                            </IconButton> :
                            <IconButton onClick={() => deleteMfaMethod(mfaTypes[1].mfa_id,'email_verified')} className={'deleteIcon'}>
                                <DeleteIcon />
                            </IconButton>
                        }
                          </div>
                        </div>
                      )}
                      {mfaTypes[2] && mfaTypes[2].type.toLowerCase() === 'sms' && (
                        <div className="mfaType">
                          <div>
                            <label>
                              {/* <input 
                                        type="radio" 
                                        name="mfaType"
                                        id="sms"
                                        ref={sms}
                                        onChange={e => {
                                            setSelectedType(mfaTypes[2]);
                                            cookies.set('mfa_type', mfaTypes[2], {
                                                path: '/',
                                                domain: `${process.env.REACT_APP_API_SERVER_sub_domian}`,
                                            });   
                                        }}
                                        /> */}
                              <span className="mfaMethod">
                                <span>
                                  {translations.SmsTitle}
                                </span>
                                <span className="mfaWrapper">
                                  {translations.SmsText}
                                    {smsVerified === true && <DoneIcon className="tickIcon"/>}
                                </span>
                              </span>
                            </label>
                          </div>
                          <div className="actionBtns">
                            {smsVerified !== true ? 
                            // (
                            //   <Button
                            //     variant="contained"
                            //     color="primary"
                            //     className="bni-background-color classes.button MuiButton-fullWidth button-focus-disable"
                            //     onClick={() =>
                            //       activateMFA({
                            //         mfa_id: mfaTypes[2].mfa_id,
                            //         type: mfaTypes[2].type,
                            //       })
                            //     }>
                            //     Add
                            //   </Button>
                            // ) : (
                            //   <Button
                            //     variant="contained"
                            //     color="primary"
                            //     className="bni-background-color classes.button MuiButton-fullWidth button-focus-disable"
                            //     onClick={() =>
                            //       activateMFA({
                            //         mfa_id: mfaTypes[2].mfa_id,
                            //         type: mfaTypes[2].type,
                            //       })
                            //     }>
                            //     Edit
                            //   </Button>
                            // )
                            <IconButton onClick={() => activateMFA({"mfa_id" :  mfaTypes[2].mfa_id , "type" :  mfaTypes[2].type})} className={'addIcon'}>
                                <AddCircleIcon />
                            </IconButton> :
                            <IconButton onClick={() => deleteMfaMethod(mfaTypes[2].mfa_id,'mobile_number_verified')} className={'deleteIcon'}>
                                <DeleteIcon />
                            </IconButton>
                            }
                          </div>
                        </div>
                      )}
                      <div>
                        {/* <Button 
                                    variant="contained"
                                    color="primary"
                                    disabled={!selectedType}
                                    className="bni-background-color classes.button MuiButton-fullWidth button-focus-disable proceedBtn"
                                    onClick={() => activateMFA()}
                                    >
                                        Proceed
                                    </Button> */}
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => onCancelAction()}
                          className="bni-background-color classes.button MuiButton-fullWidth button-focus-disable cancelBtn">
                          {translations.Cancelbutton}
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
                <FooterMenu />
              </div>
            </div>
          </ThemeProvider>
        </React.Fragment>
      );
    }
  } else {
    window.location.href = '/';
  }
}

