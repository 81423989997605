import React, { useState, useEffect } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
// import * as staticTextKey from '../../utils/translation-variable';
import userLogo from '../static/imgs/userLogo.png';
import Http from '../services/http';
import { dataSources } from '../utils/data-sources';
import { TextField, Button, Modal, IconButton, Typography, Divider, Backdrop, CircularProgress } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { FooterMenu } from '../components/footer-menu';
import { AppLayout } from '../components/app-layout';
import theme from '../utils/theme';
import { makeStyles } from '@material-ui/core/styles';
import { Cookies } from 'react-cookie';

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  modal: {
    position: 'absolute',
    background: 'white',
    width: 440,
    height: 470,
    boxShadow: theme.shadows[5],
  },
}));

function getModalStyle() {
  return {
    top: '50%',
    left: '50%',
    transform: `translate(-50%, -50%)`,
    height: '150px',
  };
}

export function Totp(props) {
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState();
  const [base64String, setBase64String] = useState('');
  const [code, setCode] = useState('');
  const [error, setError] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const http = Http.getInstance();
  var cookies = new Cookies();
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);
  const authorised_user = cookies.get('authorised_user');
  const dataSource = dataSources();
  const default_mfa_type = JSON.parse(localStorage.getItem("selectedMFAMethod"));
  const [translations, setTranslations] = useState({
        TOTPheader: 'Multifactor Authentication setup for authenticator app verification',
        ScanCodeTitle: 'Scan code or enter key',
        ScanCodeText: 'Scan the code below with your authenticator app to add this account. Some authenticator apps also allow you to type in the text version instead',
        EnterCodeTitle: 'Enter code from authenticator app',
        CodeErrorText: 'Invalid user authentication code. Please retry!',
        EnterCodeText: 'Enter the code from your authenticator app to verify and activate multi-factor authentication for this account',
        ActivateButton: 'Activate',
        CancelButton: 'CANCEL',
        SuccessMessageTitle: 'SUCCESS',
        SuccessMessageText: 'You are successfully authenticated!',
        SuccessOk: "OK",
        recoveryCodeText: 'Kindly save above code, it can be used to access your account in the event you lose access to your device'
});
    // (props.location.default_mfa_type !== undefined || props.location.default_mfa_type !== null) &&
    // props.location.default_mfa_type;

    useEffect(()=>{
      const translationData = JSON.parse(localStorage.getItem('translations') || '{}');
      if (translationData) {
        setTranslations({TOTPheader: translationData['portal-totp_title'] || 'Multifactor Authentication setup for authenticator app verification',
        ScanCodeTitle: translationData['portal-totp_scan_code_title'] || 'Scan code or enter key',
        ScanCodeText: translationData['portal-totp_scan_code_text'] || 'Scan the code below with your authenticator app to add this account. Some authenticator apps also allow you to type in the text version instead',
        EnterCodeTitle: translationData['portal-totp_enter_code_title'] || 'Enter code from authenticator app',
        CodeErrorText: translationData['portal-totp_enter_code_error_text'] || 'Invalid user authentication code. Please retry!',
        EnterCodeText: translationData['portal-totp_enter_code_text'] || 'Enter the code from your authenticator app to verify and activate multi-factor authentication for this account',
        ActivateButton: translationData['portal-totp_enter_code_activate_button'] || 'Activate',
        CancelButton: translationData['portal-totp_enter_code_cancel_button'] || 'CANCEL',
        SuccessMessageTitle: translationData['portal-totp_enter_code_activation_success_message_title'] || 'SUCCESS',
        SuccessMessageText: translationData['portal-totp_enter_code_activation_success_message_text'] || 'You are successfully authenticated!',
        SuccessOk: translationData['portal-totp_enter_code_activation_success_message_ok'] || "OK",
        recoveryCodeText: translationData['portal-totp_security_disclaimer_text'] || 'Kindly save above code, it can be used to access your account in the event you lose access to your device'
      })
      }
    },[])

  let TOTPheader;
  let ScanCodeTitle;
  let ScanCodeText;
  let EnterCodeTitle;
  let EnterCodeText;
  let CodeErrorText;
  let ActivateButton;
  let CancelButton;
  let SuccessMessageTitle;
  let SuccessMessageText;
  let SuccessOk;
  let recoveryCodeText;

  useEffect(() => {
    if (authorised_user === 'true') {
      getCodes();
    }
  }, []);

  const getCodes = () => {
    http
      .postData(`${dataSource.qrCode}`, {}, '')
      .then(res => {
        if (res.status === 200) {
          setBase64String(res.data.qr_code);
          setCode(res.data.shared_secret);
        } else if (res.status === 409) {
          http
            .getData(`${dataSource.qrCode}`, '', '')
            .then(res => {
              if (res.status === 200) {
                setBase64String(res.data.qr_code);
                setCode(res.data.shared_secret);
              }
            })
            .catch(error => {
              console.log('error', error);
            });
        }
      })
      .catch(res => {
        console.log('error', res);
      });
  };

  const handleOtp = event => {
    if (event.target.value.length === 0) {
      setError(false);
    }
    setOtp(event.target.value);
  };

  const activateTotp = () => {
    if(otp){
      setLoading(true);
      const code = { code: otp.trim() };
      http
        .postData(`${dataSource.validateTotp}`, code, '')
        .then(res => {
          if (res.status === 200) {
            if (res.data.valid === true) {
              //setOpenAlert(true);
              setError(false);
              const user = {
                mfa_enabled: true,
                default_mfa_type: default_mfa_type,
                totp_verified: true,
              };
              http
                .patchData(`${dataSource.users}`, user, '')
                .then(res => {
                  if (res.status === 200) {
                    cookies.set('mfa_enabled', true, {
                      path: '/',
                      domain: `${process.env.REACT_APP_API_SERVER_sub_domian}`,
                    });
                    cookies.set('default_mfa_type', default_mfa_type, {
                      path: '/',
                      domain: `${process.env.REACT_APP_API_SERVER_sub_domian}`,
                    });
                    cookies.set('totp_verified', true, {
                      path: '/',
                      domain: `${process.env.REACT_APP_API_SERVER_sub_domian}`,
                    });
                    http.postData(`${dataSource.mfaMethods}/${default_mfa_type.mfa_id}`, '', '')
                    .then(res => {
                      console.log("res",res);
                      setLoading(false);
                      setOpenAlert(true);
                    }).catch(error => {
                      console.log("error", error);
                      setLoading(false);
                    });
                    //setTimeout(()=>{window.location.href="/launcher"},3000);
                  }
                })
                .catch(error => {
                  console.log(error);
                  setLoading(false);
                });
            } else if (res.data.valid === false) {
              console.log('invalid otp');
              setLoading(false);
              setOpenAlert(false);
              setError(true);
            }
          } else if (res.status === 400) {
            setLoading(false);
            setOpenAlert(false);
            setError(true);
          }
        })
        .catch(error => {
          console.log(error);
          setLoading(false);
        });
    }
  };

  const handleClose = () => {
    setOpenAlert(false);
    window.location.href = '/myaccount/mfa/settings';
  };

  if (authorised_user === 'true') {
    return (
      <React.Fragment>
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <ThemeProvider theme={theme}>
          <div dir="ltr">
            <AppLayout
              apps={JSON.parse(localStorage.getItem('apps'))}
              errorDisplay={localStorage.getItem('errorDisplay') === 'false' ? false : true}
              errorResponse={localStorage.getItem('error')}
            />
            {/* 
                  { verified && <Alert 
                                icon={false} 
                                className="successNotification"
                                onClose={() => {setVerified(false)}}>
                                  You are successfully authenticated!
                                </Alert> 
                  } */}

            <Modal
              open={openAlert}
              onClose={handleClose}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description">
              <div style={modalStyle} className={classes.modal}>
                <div className={'dialogHeader'}>
                  <Typography color="textPrimary" className={'dialogTitle'}>
                    {translations.SuccessMessageTitle}
                  </Typography>
                  <IconButton onClick={handleClose} className={'closeButton'}>
                    <CloseIcon />
                  </IconButton>
                </div>
                <Divider />
                <div className={'successMsg'}>
                  {translations.SuccessMessageText}
                </div>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    handleClose();
                  }}
                  className="bni-background-color classes.button MuiButton-fullWidth button-focus-disable actionBtn">
                  {translations.SuccessOk}
                </Button>
              </div>
            </Modal>

            <div className="totpRoot">
              <h5 className="totpTitle">
                {translations.TOTPheader}
              </h5>

              <div className="totp">
                <div className="codeSection">
                  <h5>{translations.ScanCodeTitle}</h5>
                  <div>
                    {translations.ScanCodeText}
                  </div>

                  {base64String.length > 0 && (
                    <img
                      alt="qrcode"
                      height="200"
                      width="200"
                      src={`data:image/png;base64,${base64String}`}
                    />
                  )}
                  {code.length > 0 && <div>
                    <div className="activationCode">{code}</div>
                    <div>{translations.recoveryCodeText}
                    </div>
                  </div>
                  }
                </div>

                <div className="otpVerifySection">
                  <img alt="user" className="userLogo" src={userLogo} />
                  <h5>
                    {translations.EnterCodeTitle}
                  </h5>
                  <div>
                    {translations.EnterCodeText}
                  </div>

                  <TextField
                    className="otpField"
                    variant="outlined"
                    fullWidth
                    value={otp}
                    required
                    onChange={e => handleOtp(e)}
                    onKeyDown={e => {
                      if (e.keyCode === 13) {
                        e.preventDefault();
                        activateTotp();
                      }
                    }}
                  />

                  {error && (
                    <div className="otpErrorMsg">
                      {translations.CodeErrorText}
                    </div>
                  )}

                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      activateTotp();
                    }}
                    className="bni-background-color classes.button MuiButton-fullWidth button-focus-disable activateBtn">
                    {translations.ActivateButton}
                  </Button>

                  <Button
                    variant="outlined"
                    onClick={() => {
                      window.location.href = '/myaccount/mfa/settings';
                    }}
                    id="cancel"
                    className="classes.button MuiButton-fullWidth button-focus-disable navigateMethod">
                    {translations.CancelButton}
                  </Button>
                </div>
              </div>
            </div>

            <FooterMenu />
          </div>
        </ThemeProvider>
      </React.Fragment>
    );
  } else {
    window.location.href = '/';
  }
}
