import React, { useState, useEffect } from 'react';
import Http from '../services/http';
import { dataSources } from '../utils/data-sources';
import { Redirect } from 'react-router-dom';
import { LoginLayout } from '../components/login-layout/login-layout-new';
import logo from '../static/imgs/BNIportal_logo_RGB_vertical.png';
import {
  Grid,
  Typography,
  Button,
  TextField,
  Paper,
  Backdrop,
  CircularProgress,
  Modal,
  Divider,
  IconButton
} from '@material-ui/core';
import lockImg from '../static/imgs/lockImg.png';
import axios from 'axios';
import { Cookies } from 'react-cookie';
import { staticTranslationData } from '../utils/static-translation-text';
import * as staticTextKey from '../utils/translation-variable';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

// const translationData = JSON.parse(localStorage.getItem('VerifyTranslations') || '{}');

// let SmsVerifyTitle;
// let SmsVerifyText;
// let RemainingTime;
// let NoReceiveCode;
// let Resend;
// let Loginbtn;
// let Cancelbtn;

// if (translationData) {
//   SmsVerifyTitle = translationData['portal-login-sms_verify_title'];
//   SmsVerifyText = translationData['portal-login-sms_verify_text'];
//   RemainingTime = translationData['portal-sms_enter_code_remaining_time'];
//   NoReceiveCode = translationData['portal-sms_enter_code_didnt_recieve_code'];
//   Resend = translationData['portal-sms_enter_code_resend'];
//   Loginbtn = translationData['portal-login-sms_verify_login_button'];
//   Cancelbtn = translationData['portal-login-smsverify_cancel_button'];
// }

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  exceptionModal: {
    position: 'absolute',
    background: 'white',
    width: 440,
    height: 172,
    boxShadow: theme.shadows[5],
  }
}));

function getModalStyle() {
  return {
    top: '50%',
    left: '50%',
    transform: `translate(-50%, -50%)`,
    // height: '150px',
  };
}

export function SMSVerificationPage(props) {
  const classes = useStyles();
  const http = Http.getInstance();
  const [pageLoading, setPageLoading] = useState(false);
  const [otp, setOtp] = useState('');
  const [error, setError] = useState(false);
  const [verified, setVerified] = useState(false);
  const [over, setOver] = useState(false);
  const [[m, s], setTime] = useState([10, 0]);
  const [showResend, setShowResend] = useState(false);
  const [disableResend, setDisableResend] = useState(false);
  const [twilioException, setTwilioException] = useState(false);
  const [translations, setTranslations] = useState({
    SmsVerifyTitle: 'Enter the user authentication code received',
    SmsVerifyText: 'Enter the code sent to your phone number',
    RemainingTime: 'Remaining time:',
    NoReceiveCode: "Didn't receive code?",
    Resend: 'Re-send',
    Loginbtn: 'LOGIN',
    Cancelbtn: 'CANCEL',
    invalidOTP: 'Invalid user authentication code. Please retry!',
    Ok: 'OK',
    Exception: 'Exception',
    TwilioException: 'Unable to send authentication code, kindly contact site admin',
    Retry: 'RETRY',
    TimerExpired: 'Timer has expired',
    retryText: 'Please click the button to retry',
    codeSentMsg: 'Authentication code has been sent'
  });
  var cookies = new Cookies();
  const dataSource = dataSources();
  const [modalStyle] = useState(getModalStyle);

  useEffect(() => {
    var data = { type: 'sms' };
    if (loggedIn === 'true' && default_mfa_type.type.toLowerCase() === 'sms') {
      http
        .postData(`${dataSource.sendOtp}`, data, '')
        .then(res => {
          if(res.status == 200){
            console.log('res', res);
            setTwilioException(false);
           }
           else{
             setTwilioException(true);
           }
        })
        .catch(error => {
          console.log(error, 'error');
        });
    }
  }, []);

  useEffect(() => {
    const timerID = setInterval(() => tick(), 1000);
    return () => clearInterval(timerID);
  });

  useEffect(() => {
    let portalMFAtranslation = JSON.parse(localStorage.getItem('VerifyTranslations'));
    setTranslations({
      SmsVerifyTitle: portalMFAtranslation['portal-login-sms_verify_title'] || 'Enter the user authentication code received',
      SmsVerifyText: portalMFAtranslation['portal-login-sms_verify_text'] || 'Enter the code sent to your phone number',
      RemainingTime: portalMFAtranslation['portal-sms_enter_code_remaining_time'] || 'Remaining time:',
      NoReceiveCode: portalMFAtranslation['portal-sms_enter_code_didnt_recieve_code'] || "Didn't receive code?",
      Resend: portalMFAtranslation['portal-sms_enter_code_resend'] || 'Resend again',
      Loginbtn: portalMFAtranslation['portal-login-sms_verify_login_button'] || 'LOGIN',
      Cancelbtn: portalMFAtranslation['portal-login-smsverify_cancel_button'] || 'CANCEL',
      invalidOTP: portalMFAtranslation['portal-login_mfa_enter_code_error_text'] || 'Invalid user authentication code. Please retry!',
      Ok: portalMFAtranslation['portal-sms_enter_code_activation_success_message_ok'] || 'OK',
      Exception: portalMFAtranslation['portal-sms_exception_text'] || 'Exception',
      TwilioException: portalMFAtranslation['portal-sms_twilio_exception'] || 'Unable to send authentication code, kindly contact site admin',
      Retry: portalMFAtranslation['portal-mfa_retry_button'] || 'RETRY',
      TimerExpired: portalMFAtranslation['portal-mfa_timer_expired_msg'] || 'Timer has expired',
      retryText: portalMFAtranslation['portal-mfa_retry_text'] || 'Please click the button to retry',
      codeSentMsg: portalMFAtranslation['portal-mfa_code_sent_msg'] || "Authentication code has been sent"
    });
  }, []);

  // useEffect(() => {
  //   const getTranslationDataMFA = async () => {
  //     try {
  //       let locale = http.getLocale();
  //       const cookies = new Cookies();
  //       const dataSource = dataSources();
  //       let bniLang = cookies.get('bni_language_select');
  //       let lang = bniLang !== undefined ? bniLang : locale.lang_code;
  //       axios({
  //         method: 'post',
  //         url: `${dataSource.translateApi}/${lang}/search`,
  //         data: {
  //           has_translation: true,
  //           group_code: 'portal-mfa',
  //           page_size: 150,
  //         },
  //         headers: {
  //           'Content-Type': 'application/json',
  //           client_name: `${process.env.REACT_APP_clientName}`,
  //           client_secret: `${process.env.REACT_APP_clientSecret}`,
  //           'x-iso-ids-only': 'N',
  //         },
  //       }).then(response => {
  //         const translationRawData = response.data.translations;

  //         let portalMFAtranslation = {};
  //         translationRawData.map(function (row) {
  //           portalMFAtranslation[row.lang_key] = row.lang_value ? row.lang_value : row.en_val;
  //         });
  //         localStorage.setItem('lang', JSON.stringify(lang));
  //         localStorage.setItem('VerifyTranslations', JSON.stringify(portalMFAtranslation));
  //         setTranslations({
  //           SmsVerifyTitle:
  //             setTranslations['portal-login-sms_verify_title'] ||
  //             'Enter the user authentication code received',
  //           SmsVerifyText:
  //             setTranslations['portal-login-sms_verify_text'] ||
  //             'Enter the code sent to your phone number.',
  //           RemainingTime:
  //             setTranslations['portal-sms_enter_code_remaining_time'] || 'Remaining time:',
  //           NoReceiveCode:
  //             setTranslations['portal-sms_enter_code_didnt_recieve_code'] || "Didn't receive code?",
  //           Resend: setTranslations['portal-sms_enter_code_resend'] || 'Resend again',
  //           Loginbtn: setTranslations['portal-login-sms_verify_login_button'] || 'LOGIN',
  //           Cancelbtn: setTranslations['portal-login-smsverify_cancel_button'] || 'CANCEL',
  //           invalidOTP:
  //             portalMFAtranslation['portal-login_mfa_enter_code_error_text'] ||
  //             'Invalid user authentication code. Please retry!',
  //         });
  //       });
  //     } catch (error) {
  //       //handle error
  //       setLoading(false);
  //       console.log(error);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };
  //   getTranslationDataMFA();
  // }, []);

  const tick = () => {
    // if (over) return;
    if( m === 9 && s === 0) disableResend ? setShowResend(false) : setShowResend(true);
    if (m === 0 && s === 0) {setOver(true); setShowResend(false); setDisableResend(false);}
    else if (m === 0 && s === 0) {
      setTime([59, 59]);
    } else if (s == 0) {
      setTime([m - 1, 59]);
    } else {
      setTime([m, s - 1]);
    }
  };

  const reset = () => {
    setTime([parseInt(10), parseInt(0)]);
    setShowResend(false);
    setDisableResend(true);
    var data = { type: 'sms' };
    http
      .postData(`${dataSource.sendOtp}`, data, '')
      .then(res => {
        if(res.status == 200){
          console.log('res', res);
          setTwilioException(false);
         }
         else{
           setTwilioException(true);
         }
      })
      .catch(error => {
        console.log(error, 'error');
      });
  };

  const verifyCode = () => {
    setPageLoading(true);
    let code = { OTP: otp.trim(), type: 'sms' };
    http
      .postData(`${dataSource.validateOtp}`, code, '')
      .then(res => {
        if (res.status === 200) {
          if (res.data.valid === true) {
            console.log('otp verified');
            setError(false);
            cookies.set('authorised_user', true, {
              path: '/',
              domain: `${process.env.REACT_APP_API_SERVER_sub_domian}`,
            });
            setVerified(true);
            setPageLoading(false);
            //setTimeout(()=>{window.location.href="/launcher"},3000)
          } else if (res.data.valid === false) {
            console.log('invalid otp');
            setError(true);
            setVerified(false);
            setPageLoading(false);
          }
        } else {
          setError(true);
          setVerified(false);
          setPageLoading(false);
        }
      })
      .catch(error => {
        console.log(error);
        setPageLoading(false);
      });
  };

  const handleOtp = event => {
    if (event.target.value.length === 0) {
      setError(false);
    }
    setOtp(event.target.value);
  };

  const handleCancel = () => {
    window.location.href = '/';
  };

  const default_mfa_type = cookies.get('default_mfa_type');
  const loggedIn = cookies.get('loggedIn');

  if (loggedIn === 'true' && default_mfa_type.type.toLowerCase() === 'sms') {
    if (verified) {
      return (
        <Redirect
          to={{
            pathname: '/launcher',
          }}
        />
      );
    } else {
      return (
        <React.Fragment>
          <Backdrop className={classes.backdrop} open={pageLoading}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <Modal
              open={twilioException}
              onClose={()=>{setTwilioException(false)}}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description">
              <div style={modalStyle} className={classes.exceptionModal}>
                <div className={'dialogHeader'}>
                  <Typography color="textPrimary" className={'dialogTitle'}>
                    {translations.Exception}
                  </Typography>
                  <IconButton onClick={()=>{setTwilioException(false)}} className={'closeButton'}>
                    <CloseIcon />
                  </IconButton>
                </div>
                <Divider />
                <div className={'successMsg'}>
                  {translations.TwilioException}
                </div>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={()=>{setTwilioException(false)}}
                  className="bni-background-color classes.button MuiButton-fullWidth button-focus-disable actionBtn">
                  {translations.Ok}
                </Button>
              </div>
            </Modal>
          <LoginLayout title="Login">
            <Paper elevation={3}>
              <div className="container-wrap">
                <form noValidate>
                  <Grid container spacing={2}>
                    <Grid item sm={12}>
                      <div className=" form-text-color">
                        <img className="img-center" src={logo} alt="BNI" height="120px" />
                      </div>
                    </Grid>
                    <Grid item sm={12}>
                      <img className="lockImg" alt="lock icon" src={lockImg} />
                    </Grid>

                    {m==0 && s==0 ? <Grid item sm={12}>
                                      <h5 className="timeoutMsg verifyTimeoutMsg">
                                        <h4>{translations.TimerExpired}</h4>
                                        <br></br>
                                        {/* <button 
                                        className="resendBtn" 
                                        onClick={() => {window.location.href = '/myaccount/mfa/settings';}}>
                                          Click Here
                                        </button> */}
                                        {translations.retryText}
                                      </h5> 
                                    </Grid> : <>
                    <Grid item sm={12}>
                      <div className="verifyInstruction">{translations.SmsVerifyTitle}</div>
                      <Typography
                        className="terms-align-center login-top-padding login-bottom-padding verifyMsg"
                        color="textSecondary">
                        {translations.SmsVerifyText}
                      </Typography>
                    </Grid>

                    <Grid item sm={12}>
                      <div className="form-group">
                        <TextField
                          id="otpField"
                          variant="outlined"
                          fullWidth
                          value={otp}
                          onChange={e => handleOtp(e)}
                          onKeyDown={e => {
                            if (e.keyCode === 13) {
                              e.preventDefault();
                              verifyCode();
                            }
                          }}
                        />
                      </div>
                    </Grid>

                    <Grid item sm={12}>
                      {error && <div className="loginOtpErrorMsg">{translations.invalidOTP}</div>}
                    </Grid>

                    <Grid item sm={12}>
                      {/* <div className="timerSection">
                                                      <div>Remaining time:</div>
                                                      <div>Didn't receive code? <a href="/">Resend again</a></div>
                                                  </div> */}

                      {/* <div className="timer">
                        <div>
                          {translations.RemainingTime}&nbsp;
                          {`${m.toString().padStart(2, '0')}:${s.toString().padStart(2, '0')}`}
                        </div>
                        <div>
                          {translations.NoReceiveCode}
                          <button className="resendBtn" onClick={() => reset()}>
                            {translations.Resend}
                          </button>
                        </div>
                      </div> */}

                    <div className="timer">
                      <div>
                        {translations.RemainingTime}&nbsp;
                        {`${m.toString().padStart(2, '0')}:${s.toString().padStart(2, '0')}`}
                      </div>
                      {showResend ? <div>
                        {translations.NoReceiveCode}
                        <button className="resendBtn" onClick={() => reset()}>
                          {translations.Resend}
                        </button>
                      </div> : disableResend && <div className="codeSentMsg">{translations.codeSentMsg}</div>}
                    </div>
                    </Grid>

                    </>
                    }

                    <Grid item sm={12}>
                      <div className="form-align-center">
                      {m==0 && s==0 ? 
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          handleCancel()
                        }}
                        className="bni-background-color MuiButton-fullWidth button-focus-disable">
                        {translations.Retry}
                      </Button> : 
                      <div>
                          <Button
                            variant="contained"
                            color="primary"
                            className="bni-background-color MuiButton-fullWidth button-focus-disable"
                            size="large"
                            onClick={() => verifyCode()}
                            id="login">
                            {translations.Loginbtn}
                          </Button>

                          <Button
                            variant="outlined"
                            className=" classes.button MuiButton-fullWidth button-focus-disable loginCancel"
                            onClick={() => handleCancel()}
                            id="cancel">
                            {translations.Cancelbtn}
                          </Button>
                        </div>
                        }
                      </div>
                    </Grid>
                  </Grid>
                </form>
              </div>
            </Paper>
          </LoginLayout>
        </React.Fragment>
      );
    }
  } else {
    window.location.href = '/';
  }
}
